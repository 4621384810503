import {computed, signal} from '@preact/signals-react';
import {cloudStorage} from '~/shared/telegram-mini-app/cloud-storage';

interface INewsNotifications {
  friendsUpdates: boolean;
}

const EMPTY_NEWS_NOTIFICATIONS: INewsNotifications = {
  friendsUpdates: false,
};

const validateCloudNewsNotifications = (
  cloudNewsNotifications: unknown,
): cloudNewsNotifications is INewsNotifications => {
  if (!cloudNewsNotifications || typeof cloudNewsNotifications !== 'object') {
    return false;
  }

  if (
    typeof (cloudNewsNotifications as INewsNotifications).friendsUpdates !==
    'boolean'
  ) {
    return false;
  }

  return true;
};

export async function getExistingNewsNotifications(): Promise<INewsNotifications> {
  const cloudNewsNotifications = await cloudStorage.get('newsNotifications');

  if (!cloudNewsNotifications) {
    return EMPTY_NEWS_NOTIFICATIONS;
  }

  try {
    const parsed = JSON.parse(cloudNewsNotifications);

    if (validateCloudNewsNotifications(parsed)) {
      return parsed;
    }

    return EMPTY_NEWS_NOTIFICATIONS;
  } catch (error) {
    return {
      friendsUpdates: true,
    };
  }
}

export async function saveNewsNotifications(
  newsNotifications: Partial<INewsNotifications>,
) {
  const existingNewsNotifications = await getExistingNewsNotifications();
  const updatedNotifications = {
    ...existingNewsNotifications,
    ...newsNotifications,
  };

  await cloudStorage.set(
    'newsNotifications',
    JSON.stringify(updatedNotifications),
  );

  notifications.value = updatedNotifications;
}

export const notifications = signal<INewsNotifications | null>(null);

export const isReady = computed(() => {
  return notifications.value !== null;
});

getExistingNewsNotifications().then((newsNotifications) => {
  notifications.value = newsNotifications;
});
