import React from 'react';
import {useAppTheme} from '~/components/kit';
import {Typography} from '~/components/kit/typography';
import {LunarLootAbsorptionAd} from '~/features/ads/black-hole-airdrop-pending-ad';
import {TrailblazerAdBanner} from '~/features/trailblazer/ui/trailblazer-ad/trailblazer-ad';
import {rem} from '~/shared/utils/style-helpers';

export function EventsContent(props: React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <div {...props}>
      <header
        css={{
          margin: 'auto',
          marginBottom: rem(24),
          textAlign: 'center',
          borderRadius: 30,
        }}
      >
        <Typography.h3
          css={{
            fontWeight: 800,
          }}
        >
          Live Events
        </Typography.h3>
        <p
          css={{
            fontWeight: 500,
            marginTop: rem(10),
            fontSize: theme.fontSize.odds.s15,
            color: theme.colors.onSurface,
          }}
        >
          Tackle these events, rake in Lunar Loot, and possibly win SWAG that's
          out of this world!
        </p>
      </header>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(12),
        }}
      >
        <LunarLootAbsorptionAd />
        <TrailblazerAdBanner />
      </div>
    </div>
  );
}
