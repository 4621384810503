import React from 'react';
import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import stakesIcon from './assets/stakes-nav.png';
import miningIcon from './assets/mining-nav.png';
import referralsIcon from './assets/referrals-nav.png';
import rewardsIcon from './assets/rewards-nav.png';
import {useTranslation} from 'react-i18next';
import navigationPointer from './assets/navigation-pointer.svg';
import {useMatch} from 'react-router-dom';
import {RoutePath} from '../routes';
import {Link} from '~/components/Link/Link';
import {opacity} from '~/shared/utils/colors';
import {useSignals} from '@preact/signals-react/runtime';

interface INavigationMenuItemProps extends React.HTMLProps<HTMLDivElement> {
  path: string;
  icon: string;
}

const NavigationMenuItem = ({
  icon,
  children,
  path,
}: INavigationMenuItemProps) => {
  const theme = useAppTheme();

  const isMatched = useMatch(path + '/*');

  return (
    <Link
      to={path}
      replace
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        textAlign: 'center',
        fontSize: theme.fontSize.odds.s11,
        color: isMatched
          ? theme.colors.onSurface
          : opacity(theme.colors.onSurface, 0.7),
      }}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <img
        src={navigationPointer}
        alt=""
        css={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          display: isMatched ? 'block' : 'none',
          width: rem(20),
          marginTop: rem(-18),
        }}
      />
      <div>
        <img
          src={icon}
          alt=""
          css={{
            maxHeight: rem(42),
          }}
        />
      </div>
      <div
        css={{
          position: 'relative',
        }}
      >
        <span
          css={{
            visibility: isMatched ? 'hidden' : 'visible',
          }}
        >
          {children}
        </span>
        <span
          css={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            top: '50%',
            fontWeight: 900,
            visibility: isMatched ? 'visible' : 'hidden',
          }}
        >
          {children}
        </span>
      </div>
    </Link>
  );
};
export function NavigationMenu() {
  useSignals();

  const theme = useAppTheme();
  const {t} = useTranslation();

  const navItems = [
    {
      path: RoutePath.Mining,
      icon: miningIcon,
      title: t('navigation.mining'),
    },
    {
      path: RoutePath.Earn,
      icon: rewardsIcon,
      title: t('navigation.earn'),
    },
    {
      path: RoutePath.Referrals,
      icon: referralsIcon,
      title: t('navigation.referrals'),
    },
    {
      path: RoutePath.Stakes,
      icon: stakesIcon,
      title: t('navigation.stakes'),
    },
  ].filter(Boolean) as Array<{path: string; icon: string; title: string}>;

  return (
    <div
      css={{
        border: '2px solid #C2C0DC',
        boxShadow: `2px 3px 0px 0px #C2C0DC`,
        borderRadius: 999999999,
        backgroundColor: theme.colors.surface,
        padding: `${rem(12)} ${rem(34)}`,
        display: 'grid',
        gridTemplateColumns: `repeat(${navItems.length}, 1fr)`,
        gap: rem(4),
      }}
    >
      {navItems.map(({path, icon, title}) => (
        <NavigationMenuItem key={path} path={path} icon={icon}>
          {title}
        </NavigationMenuItem>
      ))}
    </div>
  );
}
