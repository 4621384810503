import {signal} from '@preact/signals-react';

interface PurchaseState {
  selectedAmount: number;
  lastPurchaseAmount: number | null;
}

const state = signal<PurchaseState>({
  selectedAmount: 5,
  lastPurchaseAmount: null,
});

export const crystalsPurchaseStore = {
  // Getters
  get selectedAmount() {
    return state.value.selectedAmount;
  },
  get lastPurchaseAmount() {
    return state.value.lastPurchaseAmount;
  },

  // Setters
  setSelectedAmount(amount: number) {
    state.value = {...state.value, selectedAmount: amount};
  },

  setLastPurchaseAmount(amount: number) {
    state.value = {...state.value, lastPurchaseAmount: amount};
  },
  reset() {
    state.value = {
      selectedAmount: 5,
      lastPurchaseAmount: null,
    };
  },
};
