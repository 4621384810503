import {Card, RewardValue, useAppTheme} from '~/components/kit';
import {Button} from '~/components/kit/button/button';
import {Modal, ModalControlButton, useModals} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {ModalHeader} from '~/components/kit/modal/header';
import {useWithdrawalModel} from '../withdrawal-model';
import {Typography} from '~/components/kit/typography';
import {Star} from '~/components/kit/star';
import {StarField} from '~/components/kit/star';
import {Fragment} from 'react';
import {opacity} from '~/shared/utils/colors';
import {RokeTokens} from '~/entities/roke-tokens';
import {useDockModel} from '~/entities/dock';
import {ImgTransition} from '~/components/kit/img-transition';
import errorIcon from '../assets/error.png';
import successIcon from '../assets/success.png';

export const WITHDRAWAL_PROCESS_MODAL_NAME = 'withdrawal-process-modal';

export function WithdrawalModal() {
  const theme = useAppTheme();
  const modals = useModals();
  const dockModel = useDockModel();
  const withdrawalModel = useWithdrawalModel();
  const isMinting = withdrawalModel.createRequestMutation.isPending;

  const rokeBalance = dockModel.dock.airdrop_balance;
  const formattedRokeBalance = RokeTokens.format(rokeBalance || 0, {
    precision: 2,
  });

  let title = 'Blast all ROKE to your wallet! 🚀';
  let description =
    "One-click, full send. No cherry-picking! Minting in progress? Chill time! No new requests till this one's done";
  let buttonText = 'Mint All';
  let cardText = 'Available ROKE tokens for mint:';
  let messageAboutNewRequests: string | null =
    "No new requests till this one's done";

  if (isMinting) {
    buttonText = 'Minting...';
  }

  if (withdrawalModel.createRequestMutation.isError) {
    title = 'Something went wrong';
    description = 'Your order has not been processed';
    cardText = 'Minting:';
    buttonText = 'Try again';
    messageAboutNewRequests = null;
  }

  if (withdrawalModel.createRequestMutation.isSuccess) {
    title = 'ROKE launch initiated!';
    description = '';
    cardText = 'You have successfully minted:';
    messageAboutNewRequests = null;
  }

  function handleMintButtonClick() {
    withdrawalModel.createRequestMutation.mutate();
  }

  function handleSupportLinkClick() {
    // TODO: Need to define support contact
  }

  return (
    <Modal
      name={WITHDRAWAL_PROCESS_MODAL_NAME}
      isClosable={true}
      controlButton={
        withdrawalModel.createRequestMutation.isSuccess && (
          <ModalControlButton
            onClick={() => {
              modals.close();
            }}
            css={{
              marginTop: rem(20),
            }}
          >
            Okay!
          </ModalControlButton>
        )
      }
    >
      {withdrawalModel.createRequestMutation.isError && (
        <ImgTransition
          src={errorIcon}
          alt="sitting-astronaut"
          css={{
            display: 'flex',
            width: rem(100),
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: rem(16),
          }}
        />
      )}

      {withdrawalModel.createRequestMutation.isSuccess && (
        <StarField
          css={{
            width: '100%',
          }}
          stars={
            <Fragment>
              <Star
                starSize={1}
                x={0.03}
                y={0.7}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#6BD23A"
                rotation={-103}
              ></Star>
              <Star
                starSize={1}
                x={0.05}
                y={0.03}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#69EDFF"
                rotation={-105}
              ></Star>
              <Star
                starSize={1}
                x={0.15}
                y={0.72}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FF6AF9"
                rotation={60}
              ></Star>
              <Star
                starSize={0.7}
                x={0.65}
                y={0.8}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#231F30"
                rotation={20}
              ></Star>
              <Star
                starSize={0.5}
                x={0.7}
                y={0}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#69EDFF"
                rotation={-151}
              ></Star>
              <Star
                starSize={1}
                x={0.85}
                y={0.4}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#6BD23A"
                rotation={-103}
              ></Star>
              <Star
                starSize={1.2}
                x={0.9}
                y={0.6}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FF6AF9"
                rotation={-151}
              ></Star>
            </Fragment>
          }
        >
          <ImgTransition
            src={successIcon}
            alt="sitting-astronaut"
            css={{
              display: 'flex',
              width: rem(100),
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: rem(16),
            }}
          />
        </StarField>
      )}

      <ModalHeader title={title} description={description}></ModalHeader>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(8),
        }}
      >
        {messageAboutNewRequests && (
          <Typography.body
            css={{
              textAlign: 'center',
              color: theme.colors.onSurfaceTertiary,
              marginTop: rem(8),
            }}
          >
            {messageAboutNewRequests}
          </Typography.body>
        )}

        <StarField
          stars={
            <Fragment>
              <Star
                starSize={1.2}
                x={-0.04}
                y={0.5}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FF8469"
                rotation={75}
              ></Star>
              <Star
                starSize={1}
                x={0.9}
                y={0.17}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FFE76A"
                rotation={40}
              ></Star>
              <Star
                starSize={0.9}
                x={0.9}
                y={0.72}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#C2B8FF"
                rotation={60}
              ></Star>
            </Fragment>
          }
        >
          <Card
            rounding="small"
            isAlternative={true}
            css={{
              paddingLeft: rem(20),
              paddingRight: rem(20),
              paddingTop: rem(14),
              paddingBottom: rem(14),
              height: rem(105),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: rem(16),
              marginTop: rem(8),
            }}
          >
            <div>
              <h6
                css={{
                  fontSize: theme.fontSize.s6,
                  fontWeight: 500,
                  color: opacity(theme.colors.onSurfaceAlternative, 0.8),
                  marginBottom: rem(10),
                  textAlign: 'center',
                }}
              >
                {cardText}
              </h6>
              <RewardValue
                value={formattedRokeBalance}
                css={{
                  fontSize: rem(32),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: rem(8),
                }}
                rewardType="roketo"
              />
            </div>
          </Card>
        </StarField>

        {!withdrawalModel.createRequestMutation.isSuccess && (
          <Button
            color="pink"
            onClick={handleMintButtonClick}
            isDisabled={isMinting}
          >
            {buttonText}
          </Button>
        )}

        {withdrawalModel.isShowSupportLink && (
          <button
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: rem(16),
              textAlign: 'center',
              fontWeight: 800,
              textTransform: 'uppercase',
              fontSize: theme.fontSize.odds.s13,
              color: theme.colors.onSurface,
              cursor: 'pointer',
            }}
            onClick={handleSupportLinkClick}
          >
            Contact support
          </button>
        )}

        {withdrawalModel.createRequestMutation.isSuccess && (
          <div css={{display: 'flex', flexDirection: 'column', gap: rem(8)}}>
            <Typography.body css={{textAlign: 'center'}}>
              Tokens en route to your wallet. We'll give you a shout when they
              land. It can take up to 24 hours.
            </Typography.body>

            {/* <ul
              css={{
                display: 'flex',
                gap: rem(4),
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <li>App alert</li>
              <li>Telegram ping</li>
              <li>Sit back and enjoy the flight!</li>
            </ul> */}
          </div>
        )}
      </div>
    </Modal>
  );
}
