import {useMutation} from '@tanstack/react-query';
import {Button} from '~/components/kit/button/button';
import {
  Modal,
  ModalClose,
  ModalControlButton,
  useModals,
} from '~/components/kit/modal';
import {PurchaseCrystalsApi} from '../api/purchase-crystals-api';
import {useAuthorizationToken} from '~/entities/user';
import {useDockModel} from '~/entities/dock';
import {invoice} from '@telegram-apps/sdk';
import {Card, RewardValue, useAppTheme} from '~/components/kit';
import {ModalHeader} from '~/components/kit/modal/header';
import {rem} from '~/shared/utils/style-helpers';
import {StarIcon} from '../assets/star';
import crystalPurchaseBg from '../assets/crystal-purchase-bg.png';
import {ImgTransition} from '~/components/kit/img-transition';
import overButtonCrystalSvg from '../assets/over-button-crystal.svg';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {
  PURCHASE_CRYSTALS_MODAL_NAME,
  PURCHASE_SUCCESS_MODAL_NAME,
} from '../lib';
import {crystalsPurchaseStore} from '../model';
import {useSignals} from '@preact/signals-react/runtime';

//  "https://t.me/$I5-AyeTxcVPSAgAAMPAQzarTS9w" => "I5-AyeTxcVPSAgAAMPAQzarTS9w"
const getInvoiceSlug = (invoiceLink: string) => {
  return invoiceLink.split('/').pop()?.slice(1) || '';
};

const starsToUsd = (stars: number) => {
  return (stars / 100) * 2;
};

export function PurchaseCrystalsModal() {
  useSignals();

  const theme = useAppTheme();
  const dock = useDockModel();
  const modals = useModals();
  const token = useAuthorizationToken();

  const purchaseMuttation = useMutation({
    mutationKey: ['purchase-crystals'],
    mutationFn: async (amount: number) => {
      const link = await PurchaseCrystalsApi.getInvoiceLink(token, {
        crystalAmount: amount,
      });

      const slug = getInvoiceSlug(link.data.invoice_link);

      const status = await invoice.open(slug);

      if (status === 'paid') {
        dock.dockQuery.refetch();
        crystalsPurchaseStore.setLastPurchaseAmount(amount);
        modals.open(PURCHASE_SUCCESS_MODAL_NAME, {
          replace: true,
        });
        modals.closeExact(PURCHASE_CRYSTALS_MODAL_NAME);
      }

      return link;
    },
  });

  return (
    <Modal
      bgImage={
        <ImgTransition
          src={crystalPurchaseBg}
          css={{minWidth: '100%', mixBlendMode: 'difference'}}
        />
      }
      backgroundColor="#3D0099"
      name={PURCHASE_CRYSTALS_MODAL_NAME}
      controlButton={
        <div
          css={{
            marginTop: rem(25),
            display: 'flex',
            gap: rem(12),
          }}
        >
          <ModalControlButton
            color="blue"
            onClick={() => {
              modals.closeExact(PURCHASE_CRYSTALS_MODAL_NAME);
            }}
          >
            <ArrowRightIcon
              css={{
                transform: 'rotate(180deg)',
                marginRight: rem(8),
              }}
            />
            Back
          </ModalControlButton>
          <ModalClose />
        </div>
      }
    >
      <ModalHeader
        title={'Buy Crystals'}
        css={{
          color: theme.colors.onSurfaceAlternative,
        }}
      ></ModalHeader>

      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: rem(12),
          marginBottom: rem(20),
        }}
      >
        <BuyCrystalButton
          amount={1}
          onClick={(amount) => crystalsPurchaseStore.setSelectedAmount(amount)}
        />
        <BuyCrystalButton
          amount={5}
          onClick={(amount) => crystalsPurchaseStore.setSelectedAmount(amount)}
        />
        <BuyCrystalButton
          amount={20}
          onClick={(amount) => crystalsPurchaseStore.setSelectedAmount(amount)}
        />
        <BuyCrystalButton
          amount={100}
          onClick={(amount) => crystalsPurchaseStore.setSelectedAmount(amount)}
        />
      </div>

      <Button
        isDisabled={!crystalsPurchaseStore.selectedAmount}
        color="purple-gradient"
        onClick={() =>
          purchaseMuttation.mutate(crystalsPurchaseStore.selectedAmount)
        }
        css={{
          width: '100%',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ImgTransition
          src={overButtonCrystalSvg}
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(30%, -60%) rotate(5deg)',
          }}
        ></ImgTransition>
        Buy&nbsp;
        {crystalsPurchaseStore.selectedAmount && (
          <RewardValue
            value={crystalsPurchaseStore.selectedAmount}
            rewardType="crystal"
            reversed
          ></RewardValue>
        )}
        &nbsp;Crystals
      </Button>
    </Modal>
  );
}

function BuyCrystalButton({
  amount,
  onClick,
}: {
  amount: number;
  onClick?: (amount: number) => void;
}) {
  const theme = useAppTheme();

  return (
    <Card
      rounding="medium"
      shadowColor={theme.colors.onSurface}
      shadowOffsetX={2}
      shadowOffsetY={2}
      borderWidth={2}
      css={{
        display: 'flex',
        userSelect: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.2s',
        cursor: 'pointer',
        ':hover, :active': {
          transform: 'scale(1.05) translateY(-5px)',
        },
        ':active': {
          transform: 'scale(0.95) translateY(5px)',
        },
      }}
      onClick={() => onClick?.(amount)}
    >
      <div
        css={{
          marginBottom: rem(12),
          display: 'flex',
        }}
      >
        <RewardValue
          value={amount}
          rewardType="crystal"
          iconSize={rem(40)}
          reversed
          css={{
            flexDirection: 'row-reverse',
            fontSize: rem(36),
          }}
        />
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          fontSize: theme.fontSize.s6,
        }}
      >
        <StarIcon />
        <span
          css={{
            fontWeight: 700,
            color: theme.colors.onSurface,
          }}
        >
          {amount}
        </span>
        &nbsp;
        <span
          css={{
            color: theme.colors.onSurfaceSecondary,
          }}
        >
          ≈${starsToUsd(amount).toFixed(2)}
        </span>
      </div>
    </Card>
  );
}
