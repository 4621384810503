import {RewardValue, useAppTheme} from '~/components/kit';
import {Modal, useModals} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {useNavigate} from 'react-router-dom';
import {Typography} from '~/components/kit/typography';
import {Button} from '~/components/kit/button/button';
import {RoutePath} from '~/app/navigation/routes';
import {useDockModel} from '~/entities/dock';
import {WalletLogoutIcon} from './assets/logout-icon';
import {useEarnModel} from '~/entities/earn';
import {useCurrentUser} from '~/entities/user';
import {Icon} from '~/components/kit/icons';
import {EthereumIcon} from '~/components/kit/icons/ethereum-icon';
import {ChainLinkIcon} from '~/components/kit/icons/chain-link';
import {RokeTokens} from '~/entities/roke-tokens';
import {Chip} from '~/components/kit/chip';
import {Delimiter} from '~/components/kit/delimiter';
import {useWithdrawalModel} from '~/features/withdrawal/withdrawal-model';
import {Link} from '~/components/Link/Link';
import {Fragment} from 'react';
import {PURCHASE_CRYSTALS_MODAL_NAME} from '../purchase-crystals/lib';
import {isMintingEnabled} from '../withdrawal/feature-flag';

export const WALLET_MENU_MODAL_NAME = 'wallet-menu-modal';

function trimMiddle(address: string) {
  return address.slice(0, 6) + '...' + address.slice(-4);
}

export function WalletControlMenu({
  afterClose,
  top,
  floatingMenuIcon,
}: {
  afterClose?: () => void;
  top: number;
  floatingMenuIcon?: React.ReactNode;
}) {
  const theme = useAppTheme();
  const dockModel = useDockModel();
  const earnModel = useEarnModel();
  const user = useCurrentUser();
  const withdrawalModel = useWithdrawalModel();
  const modals = useModals();

  const rokeBalance = dockModel.dock.airdrop_balance;
  const formattedRokeBalance = RokeTokens.format(rokeBalance || 0, {
    precision: 2,
  });

  const modalStyles = {
    top: rem(top),
    marginLeft: 'auto',
    width: rem(220),
    padding: rem(16),
  };

  return (
    <Modal
      name={WALLET_MENU_MODAL_NAME}
      isClosable={true}
      afterClose={afterClose}
      radius={16}
      borderWidth={1}
      hideCloseButton={true}
      css={modalStyles}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(8),
        }}
      >
        {floatingMenuIcon}
        <p
          css={{
            fontWeight: 500,
            display: 'flex',
            marginTop: rem(8),
            fontSize: theme.fontSize.odds.s13,
            color: theme.colors.onSurfaceTertiary,
          }}
        >
          Your wallet
          <button
            css={{
              display: 'flex',
              marginLeft: 'auto',
              gap: rem(4),
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              earnModel.disconnectWalletMutation.mutate();
            }}
          >
            Log out
            <WalletLogoutIcon />
          </button>
        </p>

        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon size={24}>
            <EthereumIcon />
          </Icon>
          <Icon
            size={16}
            css={{
              marginRight: rem(6),
              marginLeft: rem(4),
            }}
          >
            <ChainLinkIcon />
          </Icon>
          <div
            css={{
              fontSize: theme.fontSize.s5,
              fontWeight: 500,
            }}
          >
            {trimMiddle(user.wallet_address)}
          </div>
        </div>

        <Delimiter
          color={theme.colors.onSurfaceTertiary}
          css={{
            marginTop: rem(12),
            marginBottom: rem(4),
          }}
        />

        <Typography.body css={{color: theme.colors.onSurfaceTertiary}}>
          ROKE tokens balance
        </Typography.body>

        <RewardValue
          value={formattedRokeBalance}
          css={{
            alignItems: 'center',
            verticalAlign: 'center',
            fontSize: theme.fontSize.s2,
            flexDirection: 'row-reverse',
            marginRight: 'auto',
            gap: rem(4),
          }}
          rewardType={'roketo'}
        />

        {isMintingEnabled.value && (
          <>
            {withdrawalModel.canMint && (
              <Button
                onClick={withdrawalModel.showWithdrawalModal}
                color={'pink'}
                disabled={!withdrawalModel.hasUserEnoughBalance}
                css={{
                  marginTop: rem(8),
                  width: '100%',
                  height: rem(40),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {withdrawalModel.hasUserEnoughBalance
                  ? 'Mint'
                  : 'Nothing to Mint...'}

                {withdrawalModel.hasUserEnoughBalance && (
                  <Chip
                    css={{
                      marginLeft: rem(4),
                      backgroundColor: theme.colors.onSurface,
                      color: theme.colors.onSurfaceAlternative,
                      padding: rem(4),
                      paddingLeft: rem(8),
                      paddingBottom: rem(2),
                      flexShrink: 0,
                    }}
                    variant={'medium'}
                  >
                    <RewardValue
                      value={formattedRokeBalance}
                      css={{
                        alignItems: 'center',
                        verticalAlign: 'center',
                        gap: rem(4),
                      }}
                      rewardType={'roketo'}
                    />
                  </Chip>
                )}
              </Button>
            )}

            {!withdrawalModel.hasUserEnoughBalance && (
              <Typography.body
                css={{
                  color: theme.colors.onSurfaceTertiary,
                }}
              >
                Get ROKE tokens by opening a&nbsp;
                <Link css={{textDecoration: 'underline'}} to={RoutePath.Earn}>
                  Cosmic Case
                </Link>
                ! Low on Moon Crystals?
                <br />
                <a
                  css={{textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => {
                    modals.open(PURCHASE_CRYSTALS_MODAL_NAME);
                  }}
                >
                  Grab more here!
                </a>
              </Typography.body>
            )}

            {withdrawalModel.pendingRequests.data && (
              <Fragment>
                <Delimiter
                  color={theme.colors.onSurfaceTertiary}
                  css={{
                    marginTop: rem(12),
                    marginBottom: rem(0),
                  }}
                />

                <Typography.body>
                  <b>Woohoo! Your ROKE Token minting request is in the oven.</b>

                  <p
                    css={{
                      color: theme.colors.onSurfaceTertiary,
                    }}
                  >
                    No new requests while this one's cooking, but don't worry!
                    Once it's done, you can mint again with all those tasty new
                    tokens you've earned.
                  </p>
                </Typography.body>
              </Fragment>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
