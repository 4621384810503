import {useEarnModel} from '~/entities/earn';
import {WalletIcon} from './wallet-icon';
import {Icon} from '~/components/kit/icons';
import {Fragment, useRef, useState} from 'react';
import {rem} from '~/shared/utils/style-helpers';
import {RoutePath} from '~/app/navigation/routes';
import {useModals} from '~/components/kit/modal';
import {useNavigate} from 'react-router-dom';
import {WALLET_MENU_MODAL_NAME, WalletControlMenu} from './wallet-control-menu';
import {WithdrawalModal} from '../withdrawal/ui/withdrawal-modal';
import {FirstMintingSuccessModal, useWithdrawalModel} from '../withdrawal';

export function WalletOpenButton() {
  const earnModel = useEarnModel();
  const navigate = useNavigate();
  const [isMenuOpened, openMenu] = useState(false);
  const withdrawalModel = useWithdrawalModel();
  const modals = useModals()
  const iconRef = useRef<HTMLButtonElement>(null);

  let menuTopPosition = 0;

  const buttonTop = iconRef.current?.getBoundingClientRect().top;
  const buttonHeight = iconRef.current?.getBoundingClientRect().height;
  const buttonLeft = iconRef.current?.getBoundingClientRect().left;

  if (buttonTop && buttonHeight) {
    menuTopPosition = buttonTop + buttonHeight;
  }

  const openModal = () => {
    openMenu(true);
    modals.open(WALLET_MENU_MODAL_NAME, {replace: true});
  };

  const closeModal = () => {
    openMenu(false);
    modals.closeExact(WALLET_MENU_MODAL_NAME);
  };

  const handleButtonClick = () => {
    if (earnModel.isWalletConnected) {
      isMenuOpened ? closeModal() : openModal();
    } else {
      navigate(RoutePath.OneTimeTasks);
    }
  };

  return (
    <Fragment>
      <button
        css={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick}
        ref={iconRef}
      >
        <Icon size={40}>
          <WalletIcon isDisconnected={!earnModel.isWalletConnected} />
        </Icon>
      </button>

      {earnModel.isWalletConnected && isMenuOpened && (
        <WalletControlMenu top={menuTopPosition} afterClose={closeModal} floatingMenuIcon={
          <button
            css={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2,
              marginTop: rem(2),
              left: buttonLeft,
              top: buttonTop,
            }}
            onClick={closeModal}
          >
            <Icon size={40}>
              <WalletIcon isDisconnected={!earnModel.isWalletConnected} />
            </Icon>
          </button>
        } />
      )}

      {withdrawalModel.hasUserEnoughBalance && (
        <WithdrawalModal />
      )}

      <FirstMintingSuccessModal />

    </Fragment>
  );
}
