import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {WithdrawalApi, ITransactionUpdateBody} from './api/withdrawal-api';
import {useAuthorizationToken} from '~/entities/user';
import {useDockModel} from '~/entities/dock';
import {useModals} from '~/components/kit/modal';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {useCallback, useState} from 'react';
import {useMemo} from 'react';
import {WITHDRAWAL_PROCESS_MODAL_NAME} from './ui/withdrawal-modal';
import {RokeTokens} from '~/entities/roke-tokens';

const WITHDRAWAL_REQUESTS_KEY = 'withdrawal-requests';
const WITHDRAWAL_PENDING_REQUESTS_KEY = 'withdrawal-pending-requests';

const ROKE_BALANCE_THRESHOLD = RokeTokens.toLunar(10);

export function useWithdrawalModel() {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();
  const dock = useDockModel();
  const modals = useModals();
  const rokeBalance = dock.dock.airdrop_balance;
  const [withdrawErrorsCounter, setWithdrawErrorsCounter] = useState(0);

  const hasUserEnoughBalance = useMemo(() => {
    return rokeBalance >= ROKE_BALANCE_THRESHOLD;
  }, [rokeBalance]);

  const getRequestsQuery = useQuery({
    queryKey: [WITHDRAWAL_REQUESTS_KEY],
    queryFn: async () => {
      const response = await WithdrawalApi.getWithdrawalRequests(token);

      return response;
    },
  });

  const getPendingRequestQuery = useQuery({
    queryKey: [WITHDRAWAL_PENDING_REQUESTS_KEY],
    queryFn: async () => {
      const response = await WithdrawalApi.getWithdrawalPendingRequest(token);

      return response;
    },
  });

  const createRequestMutation = useMutation({
    mutationKey: ['create-withdrawal-request'],
    mutationFn: () => WithdrawalApi.createWithdrawalRequest(token),
    onSuccess: () => {
      dock.dockQuery.refetch();
      queryClient.invalidateQueries({queryKey: [WITHDRAWAL_REQUESTS_KEY]});
      queryClient.invalidateQueries({
        queryKey: [WITHDRAWAL_PENDING_REQUESTS_KEY],
      });
    },
    onError: () => {
      setWithdrawErrorsCounter((prev) => prev + 1);
    },
  });

  const updateRequestMutation = useMutation({
    mutationKey: ['update-withdrawal-request'],
    mutationFn: (data: ITransactionUpdateBody) =>
      WithdrawalApi.updateWithdrawalTransactionStatus(data, token),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [WITHDRAWAL_REQUESTS_KEY]});
      queryClient.invalidateQueries({
        queryKey: [WITHDRAWAL_PENDING_REQUESTS_KEY],
      });
    },
    onError: () => {
      // TODO: show error
    },
  });

  const showWithdrawalModal = useCallback(() => {
    modals.open(WITHDRAWAL_PROCESS_MODAL_NAME);
  }, [modals]);

  const requestsData = getRequestsQuery.data || makeAxiosResponse([]);
  const pendingRequestData =
    getPendingRequestQuery.data || makeAxiosResponse(null);

  const isReady = createRequestMutation.isSuccess;
  const canMint =
    getPendingRequestQuery.isSuccess &&
    pendingRequestData.data === null &&
    hasUserEnoughBalance;
  const isShowSupportLink = withdrawErrorsCounter > 1;

  return {
    requests: {
      query: getRequestsQuery,
      data: requestsData.data,
    },
    pendingRequests: {
      query: getPendingRequestQuery,
      data: pendingRequestData.data,
    },
    isReady,
    canMint,
    isShowSupportLink,
    createRequestMutation,
    updateRequestMutation,
    hasUserEnoughBalance,
    modals,
    showWithdrawalModal,
  };
}
