import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {IAuthorizationResponse} from '~/api/user';
import {apiClient} from '~/api/shared/api-client';

export enum WithdrawalRequestStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  REJECTED = 'rejected',
}

export interface ITransactionUpdateBody {
  status: WithdrawalRequestStatus;
  hash: string | null;
  id: string;
}

export interface IWithdrawalTransaction {
  status: WithdrawalRequestStatus;
  hash: string | null;
  created_at: ServerTimestamp;
  updated_at: ServerTimestamp;
}

export interface IWithdrawalRequest {
  amount: number;
  created_at: ServerTimestamp;
  transaction: IWithdrawalTransaction | null;
}

function getWithdrawalRequests(token: IAuthorizationResponse) {
  return apiClient.get<IWithdrawalRequest[]>('/withdrawal-requests/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function createWithdrawalRequest(token: IAuthorizationResponse) {
  return apiClient.post<IWithdrawalRequest>('/withdrawal-requests/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function updateWithdrawalTransactionStatus(data: ITransactionUpdateBody, token: IAuthorizationResponse) {
  return apiClient.post<IWithdrawalRequest>('/withdrawal-requests/update-transaction-status/', data, {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getWithdrawalPendingRequest(token: IAuthorizationResponse) {
  return apiClient.get<IWithdrawalRequest | null>('/withdrawal-requests/pending/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

export const WithdrawalApi = {
  getWithdrawalRequests,
  createWithdrawalRequest,
  updateWithdrawalTransactionStatus,
  getWithdrawalPendingRequest,
};
