import {useAuthorizationToken} from '~/entities/user';
import {
  IPlanetGuess,
  IPlanetOrder,
  TrailblazerApi,
} from './api/trailblazer-api';
import {useMutation, useQuery} from '@tanstack/react-query';
import {isAllQueriesReady} from '~/shared/utils/query';
import {ServerTimestamp} from '~/shared/utils/format/format-time';
import {trackTrailblazerGuess} from './analytics';

const EMPTY_GAME: IPlanetOrder = {
  created_at: 0 as ServerTimestamp,
  planet_order: [],
  id: 0,
};

const EMPTY_GUESS: IPlanetGuess = {
  user_id: 0,
  planet_order_guess: [],
  planets_decoded_count: 0,
  reward_amount: 0,
  crystal_amount: 0,
  planet_order_id: -1,
  id: -1,
  created_at: 0 as ServerTimestamp,
};
export function useTrailblazerModel() {
  const token = useAuthorizationToken();

  const guessHistory = useQuery({
    queryKey: ['trailblazer', 'history'],
    queryFn: async () => {
      const response = await TrailblazerApi.getPlanetGuesses({limit: 7}, token);
      return response.data;
    },
    refetchInterval: 0,
  });

  const guessHistoryData = guessHistory.data || [];

  const currentGame = useQuery({
    queryKey: ['trailblazer', 'currentGame'],
    queryFn: async () => {
      const response = await TrailblazerApi.getPlanetOrder(token);
      return response.data;
    },
    refetchInterval: 0,
  });

  const currentGameData = currentGame.data || EMPTY_GAME;

  const makeGuess = useMutation({
    mutationKey: ['trailblazer', 'makeGuess'],
    mutationFn: async (planetOrder: number[]) => {
      const response = await TrailblazerApi.guessPlanets({planetOrder}, token);
      return response.data;
    },
    onSuccess(guessResponse) {
      trackTrailblazerGuess(guessResponse);
      guessHistory.refetch();
    },
  });

  const hasPlayed =
    currentGameData.id === (guessHistoryData[0] || EMPTY_GUESS).planet_order_id;

  const lastGuess = guessHistoryData[0] || EMPTY_GUESS;

  return {
    isReady: isAllQueriesReady(guessHistory, currentGame),
    guessHistory: {
      query: guessHistory,
      data: guessHistoryData,
    },

    currentGame: {
      query: currentGame,
      data: currentGameData,
    },
    makeGuess,
    hasPlayed: hasPlayed,
    lastGuess,
  };
}
