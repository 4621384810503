import React from 'react';

interface StarIconProps {
  size?: number;
}

export function StarIcon({size = 16}: StarIconProps) {
  return (
    <svg
      width={size}
      height={(size * 15) / 16}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9777 0.676659C7.35592 -0.225716 8.64408 -0.225715 9.0223 0.67666L10.2804 3.67831C10.4398 4.05873 10.8003 4.31865 11.2142 4.35158L14.4797 4.61137C15.4614 4.68947 15.8595 5.90522 15.1115 6.54102L12.6236 8.65593C12.3082 8.92397 12.1705 9.34453 12.2669 9.7453L13.027 12.9075C13.2555 13.8582 12.2133 14.6095 11.3729 14.1001L8.57713 12.4055C8.22281 12.1908 7.77719 12.1908 7.42287 12.4055L4.62713 14.1001C3.78666 14.6095 2.74451 13.8582 2.97302 12.9075L3.35308 11.3264C3.35308 11.3264 3.81818 10.2195 4.7858 9.57729C5.75127 8.93647 7.31227 8.15583 8.18066 7.72156C8.34519 7.63928 8.48486 7.56943 8.59091 7.51533C8.74919 7.43457 8.68182 7.39138 8.68182 7.39138L8.51136 7.36885C8.35778 7.39553 8.14449 7.43458 7.8909 7.48101L7.89083 7.48102C6.92751 7.6574 5.38254 7.94027 4.31818 8.05617C2.97302 8.20265 2.13246 7.59847 2.13246 7.59847L0.88847 6.54102C0.140517 5.90522 0.538584 4.68947 1.52029 4.61137L4.7858 4.35158C5.19966 4.31865 5.56018 4.05873 5.71962 3.67831L6.9777 0.676659Z"
        fill="url(#paint0_linear_3082_11611)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3082_11611"
          x1="15.5"
          y1="-0.000122532"
          x2="0.622109"
          y2="14.3908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC48FE" />
          <stop offset="1" stopColor="#4F85FD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
