import {cloudStorage as _cloudStorage} from '@telegram-apps/sdk';
import {miniapp} from '..';

miniapp.init();

export const cloudStorage = {
  get(key: string): Promise<string | null> {
    return _cloudStorage.getItem(key);
  },
  set(key: string, value: string): Promise<void> {
    return _cloudStorage.setItem(key, value);
  },
  delete(key: string): Promise<void> {
    return _cloudStorage.deleteItem(key);
  },
};
