import {Card, RewardValue} from '~/components/kit';
import {Modal} from '~/components/kit/modal';
import {rem} from '~/shared/utils/style-helpers';
import {ModalHeader} from '~/components/kit/modal/header';
import {Star} from '~/components/kit/star';
import {StarField} from '~/components/kit/star';
import {Fragment} from 'react';
import {RokeTokens} from '~/entities/roke-tokens';
import {useDockModel} from '~/entities/dock';
import {HowItWorks} from '~/components/kit/how-it-works';

export const FIRST_MINTING_SUCCESS_MODAL_NAME = 'first-minting-success-modal';

export function FirstMintingSuccessModal() {
  const dockModel = useDockModel();

  const rokeBalance = dockModel.dock.airdrop_balance;
  const formattedRokeBalance = RokeTokens.format(rokeBalance || 0, {
    precision: 2,
  });

  let title = 'Your first minting!';
  let description = 'Your ROKE tokens have been successfully credited to your wallet balance!';

  return (
    <Modal
      name={FIRST_MINTING_SUCCESS_MODAL_NAME}
      isClosable={true}
    >
      <ModalHeader
        title={title}
        description={description}
      ></ModalHeader>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(8),
        }}
      >
        <StarField
          stars={
            <Fragment>
              <Star
                starSize={1.2}
                x={-0.04}
                y={0.5}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FF8469"
                rotation={75}
              ></Star>
              <Star
                starSize={1}
                x={0.9}
                y={0.17}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#FFE76A"
                rotation={40}
              ></Star>
              <Star
                starSize={0.9}
                x={0.9}
                y={0.72}
                rayColor="rgba(0, 0, 0, 1)"
                coreColor="#C2B8FF"
                rotation={60}
              ></Star>
            </Fragment>
          }
        >
          <Card
            rounding="small"
            isAlternative={true}
            css={{
              paddingLeft: rem(16),
              paddingRight: rem(16),
              paddingTop: rem(12),
              paddingBottom: rem(12),
              height: rem(80),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: rem(16),
              marginTop: rem(16),
            }}
          >
            <div css={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: rem(8)}}>
              <span
                css={{
                  color: '#00B754',
                  fontSize: rem(32),
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 800,
                }}
              >
                +
              </span>
              <RewardValue
                value={formattedRokeBalance}
                css={{
                  fontSize: rem(32),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: rem(8),
                }}
                rewardType="roketo"
              />
            </div>
          </Card>
        </StarField>

        <HowItWorks
          customText="What are ROKE tokens?"
          onClick={() => {
            // TODO: open roke token info modal
          }}
        />
      </div>
    </Modal>
  );
}
