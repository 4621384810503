import React, {useEffect} from 'react';
import {useAppTheme} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';

export function PageLayout({
  children,
  showLoader,
  ...rest
}: {
  children?: React.ReactNode;
  showLoader?: boolean;
} & React.HTMLProps<HTMLDivElement>) {
  const [wasHidden] = React.useState(showLoader);
  const theme = useAppTheme();
  const [isRevealed, setIsRevealed] = React.useState(!showLoader);

  useEffect(() => {
    if (wasHidden && !showLoader) {
      setIsRevealed(true);
    }
  }, [showLoader]);

  return (
    <div
      css={{
        maxWidth: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      <div
        css={{
          opacity: isRevealed ? 1 : 0,
          transition: 'opacity 0.3s ease',
          // minHeight: `calc(100vh + ${rem(135)})`,
          minHeight: '100vh',
          padding: rem(20),
          paddingBottom: rem(135),
          marginRight: `calc(-1 * (100vw - 100%))`,
        }}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
}
