import {computed, effect} from '@preact/signals-react';
import {userData} from '../user';
import {BLACK_HOLE_START_DATE} from '~/shared/feature-flags';
import isFeatureEnabled from '~/shared/gradual-releaase';

const ONE_HOUR = 60 * 60 * 1000;

export const isEarnPageReleasedForUser = computed(() => {
  const telegramId = userData.value?.telegram_id;

  if (!telegramId) {
    return false;
  }

  return isFeatureEnabled(
    telegramId,
    BLACK_HOLE_START_DATE,
    new Date(BLACK_HOLE_START_DATE.getTime() + ONE_HOUR * 24),
  );
});
