import {
  Modal,
  ModalClose,
  ModalControlButton,
  useModals,
} from '~/components/kit/modal';
import {useAppTheme, RewardValue, Card} from '~/components/kit';
import {rem} from '~/shared/utils/style-helpers';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {ImgTransition} from '~/components/kit/img-transition';
import confettiBgSvg from '../assets/confetti-bg.svg';
import {useDockModel} from '~/entities/dock';
import {PURCHASE_SUCCESS_MODAL_NAME} from '../lib';
import {Typography} from '~/components/kit/typography';
import {
  formatWithCommas,
  toFloatCrystals,
} from '~/shared/utils/format/format-numbers';
import {useSignals} from '@preact/signals-react/runtime';
import {crystalsPurchaseStore} from '../model';

export function PurchaseSuccessModal() {
  useSignals();

  const theme = useAppTheme();
  const modals = useModals();
  const dock = useDockModel();

  return (
    <Modal
      bgImage={
        <ImgTransition
          src={confettiBgSvg}
          css={{
            minWidth: '100%',
          }}
        />
      }
      backgroundColor="#2C0368"
      name={PURCHASE_SUCCESS_MODAL_NAME}
      controlButton={
        <div
          css={{
            marginTop: rem(25),
            display: 'flex',
            gap: rem(12),
          }}
        >
          <ModalControlButton
            color="blue"
            onClick={() => {
              modals.closeExact(PURCHASE_SUCCESS_MODAL_NAME);
            }}
          >
            <ArrowRightIcon
              css={{
                transform: 'rotate(180deg)',
                marginRight: rem(8),
              }}
            />
            Back
          </ModalControlButton>
          <ModalClose />
        </div>
      }
      afterClose={() => {
        crystalsPurchaseStore.reset();
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: rem(24),
          textAlign: 'center',
          color: theme.colors.onSurfaceAlternative,
        }}
      >
        <Card
          rounding={'medium'}
          css={{
            marginBottom: rem(16),
            width: '100%',
          }}
        >
          <div
            css={{
              fontSize: rem(24),
              fontWeight: 'bold',
              marginBottom: rem(8),
            }}
          >
            <RewardValue
              value={'+' + crystalsPurchaseStore.lastPurchaseAmount}
              rewardType="crystal"
              iconSize={rem(40)}
              reversed
              css={{
                justifyContent: 'center',
                fontSize: rem(36),
                color: theme.colors.onSurface,
              }}
            />
          </div>
          <Typography.body
            css={{
              color: theme.colors.onSurface,
              fontSize: theme.fontSize.s7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Moon Crystals Balance:{' '}
            <RewardValue
              value={formatWithCommas(
                toFloatCrystals(dock.dock.crystal_balance),
              )}
              rewardType="crystal"
              reversed
              iconSize={rem(14)}
              css={{
                fontSize: theme.fontSize.s6,
              }}
            ></RewardValue>
          </Typography.body>
        </Card>

        <Typography.h3
          css={{
            marginBottom: rem(8),
          }}
        >
          Congratulations!
        </Typography.h3>
        <p css={{color: theme.colors.onSurfaceAlternativeSecondary}}>
          You received Moon Crystals!
        </p>
      </div>
    </Modal>
  );
}
