import {miniApp, init, backButton} from '@telegram-apps/sdk';

export const miniapp = {
  init() {
    init();
    miniApp.mount();
    backButton.mount();
    miniApp.setBackgroundColor('#FFFFFF');
    miniApp.setHeaderColor('#FFFFFF');
  },
};
miniapp.init();
