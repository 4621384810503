import React from 'react';
import {useAppTheme} from '~/components/kit/theme/theme';
import {rem} from '~/shared/utils/style-helpers';

export function CosmicCaseDescription(props: React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();
  return (
    <div
      css={{
        display: 'flex',
        top: rem(210),
        color: '#FFF',
        lineHeight: '135%',
        fontSize: theme.fontSize.s4,
        fontWeight: 700,
        width: '100%',
        marginLeft: rem(-16),
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
      {...props}
    >
      <span
        css={{
          textAlign: 'center',
          '-webkit-text-stroke-width': '8px',
          '-webkit-text-stroke-color': '#000',
          paintOrder: 'stroke fill',
        }}
      >
        Tap to smash the geode
        <br />
        and get the treasure!
      </span>
    </div>
  );
}
