import {rem} from '~/shared/utils/style-helpers';
import {EventsContent} from '../components/events-content';
import {AestheticsLine, Typography} from '~/components/kit/typography';
import {Card, useAppTheme} from '~/components/kit';
import {AdsGramCard} from '~/features/ads-gram/ui/ads-gram-card';
import crystalA from '../components/assets/crystalA.png';
import crystalB from '../components/assets/crystalB.png';
import {DailyMissionCard} from '~/features/daily-mission/ui/daily-mission-card';
import {CosmicCaseBanner} from '~/features/cosmic-case';
import {useModals} from '~/components/kit/modal';
import {Button} from '~/components/kit/button/button';
import {PurchaseCrystalsModal} from '~/features/purchase-crystals/ui/purchase-modal';
import {PURCHASE_CRYSTALS_MODAL_NAME} from '~/features/purchase-crystals/lib';

export function DailyTasksPage() {
  const theme = useAppTheme();

  const modals = useModals();
  return (
    <div>
      <header
        css={{
          margin: 'auto',
          marginBottom: rem(24),
          textAlign: 'center',
          position: 'relative',
          zIndex: 1,
          borderRadius: 30,
        }}
      >
        <Typography.h3
          css={{
            fontWeight: 800,
          }}
        >
          <AestheticsLine>Daily</AestheticsLine> Tasks
        </Typography.h3>
        <p
          css={{
            fontWeight: 500,
            marginTop: rem(10),
            fontSize: theme.fontSize.odds.s15,
            color: theme.colors.onSurfaceTertiary,
            // extra padding to avoid crystals
            paddingRight: rem(60),
            paddingLeft: rem(60),
          }}
        >
          Earn stellar rewards every day just by staying active! Complete fun
          mini-tasks to instantly boost your Lunar Loot payout.
        </p>

        <div
          css={{
            display: 'flex',
            gap: rem(12),
            position: 'relative',
            // set it higher than the rewards cards
            zIndex: 2,
          }}
        >
          <img
            src={crystalB}
            css={{
              width: rem(120),
              position: 'absolute',
              left: 0,
              bottom: rem(-40),
              transform: `translateX(-25%)`,
            }}
          ></img>
          <img
            src={crystalA}
            css={{
              width: rem(100),
              position: 'absolute',
              right: 0,
              bottom: rem(-40),
              transform: `translateX(25%)`,
            }}
          ></img>
        </div>
      </header>

      <DailyMissionCard
        css={{
          marginBottom: rem(12),
        }}
      />

      <AdsGramCard
        css={{
          marginBottom: rem(40),
        }}
      />

      <CosmicCaseBanner
        css={{
          marginBottom: rem(40),
        }}
      />

      <EventsContent />
    </div>
  );
}
