import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {type FC, useEffect} from 'react';
import {App} from '~/app/App';

import {SentryErrorBoundary} from '~/shared/sentry';
import {AppThemeProvider} from '../kit';
import {FullscreenLoaderProvider} from '../kit/full-screen-loader';
import {captureException, withScope} from '@sentry/react';

// Create a client
const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        // Set sentry's scope to mutation id
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            Array.from(mutation.options.mutationKey) as string[],
          );
        }

        captureException(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', {
          queryHash: query.queryHash,
        });
        scope.setFingerprint([query.queryHash.replace(/[0-9]/g, '0')]);
        captureException(err);
      });
    },
  }),
});

export const Root: FC = () => {
  // const manifestUrl = useMemo(() => {
  //   return new URL('tonconnect-manifest.json', window.location.href).toString();
  // }, []);

  // Enable debug mode to see all the methods sent and events received.
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') {
  //     setDebug(true);
  //   }
  // }, []);

  return (
    <AppThemeProvider>
      <FullscreenLoaderProvider>
        <SentryErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </SentryErrorBoundary>
      </FullscreenLoaderProvider>
    </AppThemeProvider>
  );
};
