import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {MicroButton} from '~/components/kit/button/button';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import moonSvg from '~/components/kit/reward-value/assets/moon.svg';
import crystalSvg from '~/components/kit/reward-value/assets/crystal.svg';
import rokeTokenSvg from '~/components/kit/reward-value/assets/roke.svg';

import {FallingImages} from './animation';
import {formatCountdownWords} from '~/shared/utils/format/format-time';
import {BLACK_HOLE_START_DATE} from '~/shared/feature-flags';

export function AirdropAd() {
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="#61D991"
      radius={30}
      css={{
        padding: rem(16),
        color: theme.colors.onSurfaceAlternative,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: rem(120),
      }}
      bgImage={
        <FallingImages
          imageSrc={[moonSvg, moonSvg, crystalSvg, rokeTokenSvg]}
          visibleImages={10}
        />
      }
    >
      <Typography.h3
        css={{
          lineHeight: 1,
          marginBottom: rem(8),
        }}
      >
        Airdrop S2 Incoming in
      </Typography.h3>
      <Typography.h1
        css={{
          lineHeight: 1,
          fontSize: rem(32),
        }}
      >
        {formatCountdownWords(BLACK_HOLE_START_DATE)}
      </Typography.h1>
    </Box>
  );
}
