import {RewardValue, useAppTheme} from '~/components/kit';
import {Bonus} from '~/components/kit/bonus';
import {Box} from '~/components/kit/box';
import {Button} from '~/components/kit/button/button';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import crystalsBoxSvg from '../assets/cosmic-case-banner-crystalls.svg';
import buttonLeftStarsSvg from '../assets/cosmic-case-banner-button-stars-left.svg';
import buttonRightStarsSvg from '../assets/cosmic-case-banner-button-stars-right.svg';
import {ImgTransition} from '~/components/kit/img-transition';
import {Fragment} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {COSMIC_CASE_PRICE} from '../model';
import {RoutePath} from '~/app/navigation/routes';
import {useDockModel} from '~/entities/dock';
import {toFloatCrystals} from '~/shared/utils/format/format-numbers';
import {Ribbon} from '~/components/kit/ribbon';

export function CosmicCaseBanner(props: React.HTMLProps<HTMLDivElement>) {
  const dock = useDockModel();
  const balance = dock.dock.crystal_balance;
  const navigate = useNavigate();
  const isBalanceEnough = toFloatCrystals(balance) >= COSMIC_CASE_PRICE;

  const goToGamePage = () => {
    navigate(RoutePath.CosmicCase);
  };

  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="linear-gradient(180deg, #6D11CA 0%, #220F35 100%)"
      radius={30}
      css={{
        padding: rem(24),
      }}
      {...props}
    >
      <div
        css={{
          color: theme.colors.onSurfaceAlternative,
          marginBottom: rem(16),
        }}
      >
        <Typography.h3
          css={{
            marginBottom: rem(8),
          }}
        >
          Surprise Cases
          <br />
          are here!
        </Typography.h3>
        <Typography.body css={{opacity: 0.7}}>
          Crack one open now! What's inside?
          <br />
          Only one way to find out!
        </Typography.body>
      </div>

      <Ribbon
        variant="new"
        css={{
          position: 'absolute',
          right: rem(-16),
          top: rem(24),
          zIndex: 5,
          textAlign: 'center',
        }}
      />

      <ImgTransition
        src={crystalsBoxSvg}
        css={{
          width: rem(160),
          transform: 'rotate(-9deg)',
          position: 'absolute',
          bottom: rem(-16),
          right: rem(-10),
          zIndex: 0,
        }}
      ></ImgTransition>

      <Button
        color="white"
        css={{
          width: '100%',
          background: 'linear-gradient(88deg, #E446BA 0%, #7C32FF 100%)',
          filter: isBalanceEnough ? 'none' : 'grayscale(0.9)',
          color: '#FFF',
          position: 'relative',
          zIndex: 2,
          fontWeight: 'black',
        }}
        isDisabled={!isBalanceEnough}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(RoutePath.CosmicCase);
        }}
      >
        <Fragment>
          Open case for{' '}
          <Bonus
            color="alternative"
            variant="medium"
            css={{
              marginLeft: rem(4),
              fontSize: rem(16),
              minWidth: 0,
              paddingLeft: rem(8),
              paddingRight: rem(8),
            }}
          >
            <RewardValue value={COSMIC_CASE_PRICE} rewardType="crystal" />
          </Bonus>
        </Fragment>

        <ImgTransition
          src={buttonLeftStarsSvg}
          css={{
            width: rem(22),
            position: 'absolute',
            left: rem(16),
            top: rem(8),
          }}
        ></ImgTransition>

        <ImgTransition
          src={buttonRightStarsSvg}
          css={{
            width: rem(18),
            position: 'absolute',
            right: rem(11),
            top: rem(12),
          }}
        ></ImgTransition>
      </Button>
    </Box>
  );
}
