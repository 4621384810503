import {useIntegration} from '@telegram-apps/react-router-integration';
import {useLaunchParams} from '@telegram-apps/sdk-react';
import React, {type FC, Suspense, useEffect, useMemo, useRef} from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  useNavigate,
} from 'react-router-dom';
import {renderRoute, routes} from '../navigation/routes.tsx';
import {ModalRoot} from '~/components/kit/modal/context.tsx';
import {AuthenticationHandler} from '~/entities/user/user.tsx';
import {isAllQueriesReady} from '~/shared/utils/query/index.ts';
import {useDockModel} from '~/entities/dock/dock.ts';
import {logger} from '~/shared/debug/index.ts';
import {FullscreenLoader} from '~/components/kit/full-screen-loader/full-screen-loader.tsx';
import {FullscreenError} from '~/components/kit/full-screen-error/full-screen-error.tsx';
import '~/app/analytics.ts';
import {isValidOpen, parseStartParam} from '~/shared/start-params/index.ts';
import {PageLayout} from '~/components/layouts/page-layout/page-layout.tsx';
import {NavigationMenu} from '../navigation/navigation-menu/navigation-menu.tsx';
import {SentryRoutes} from '~/shared/sentry/sentry.tsx';
import {rem} from '~/shared/utils/style-helpers/units.ts';
import {ToastContainer} from '~/features/toasts/toasts.tsx';
import {AddFriendProcess} from '~/features/friends/index.ts';
import {useSignals} from '@preact/signals-react/runtime';
import {OnboardingProcess} from '~/features/onboarding/onboarding-process.tsx';
import {NewsNotificationProcess} from '~/entities/news-notifications/index.ts';
import {
  FRIENDS_UPDATE_NOTIFICATION_MODAL_NAME,
  FriendsUpdateNotification,
} from '~/features/friends/friends-update-notification.tsx';
import {EnvironmentVariables} from '../env.ts';
import {TechnicalMaintenance} from '~/features/technical-maintenance/technical-maintenance.tsx';
import isFeatureEnabled from '~/shared/gradual-releaase/index.ts';

function ApplicationRoutes() {
  useSignals();
  const launchParams = useLaunchParams();
  const params = parseStartParam(launchParams.startParam || '');

  return (
    <React.Fragment>
      <OnboardingProcess />
      <NewsNotificationProcess
        modalName={FRIENDS_UPDATE_NOTIFICATION_MODAL_NAME}
        renderNotification={(finalize) => {
          return <FriendsUpdateNotification onFinalize={finalize} />;
        }}
      />

      {params.friendCode && <AddFriendProcess friendCode={params.friendCode} />}
      <Suspense fallback={<PageLayout showLoader={true}></PageLayout>}>
        <SentryRoutes>
          {routes.map((route) => {
            return renderRoute(route);
          })}
          <Route path="*" element={<Navigate to="/" />} />
        </SentryRoutes>
      </Suspense>
      <div
        css={{
          position: 'fixed',
          width: `calc(100vw - ${rem(40)})`,
          // left: rem(20),
          // right: rem(20),
          bottom: rem(20),
          left: rem(20),
          zIndex: 2,
        }}
      >
        <NavigationMenu />
      </div>
    </React.Fragment>
  );
}

function DataFetcher({children}: {children: React.ReactNode}) {
  const dock = useDockModel();

  if (isAllQueriesReady(dock.dockQuery)) {
    return children;
  }
  return <FullscreenLoader />;
}

function InitialRedirect({children}: {children: React.ReactNode}) {
  const params = useLaunchParams();
  const navigate = useNavigate();

  const didRedirect = useRef(false);
  useEffect(() => {
    if (didRedirect.current) return;

    const startParams = parseStartParam(params.startParam || '');
    logger.debug('[InitialRedirect]: params', startParams);

    if (startParams.open && isValidOpen(startParams.open)) {
      logger.debug('[InitialRedirect]: Redirecting to', startParams.open);

      navigate(startParams.open);
      didRedirect.current = true;
    }
  }, []);

  return children;
}

const BOOST_RELEASE_START = new Date('2024-07-20T12:28:26.782Z');

const IS_READY_FOR_USER = (userId: number) =>
  isFeatureEnabled(
    userId,
    BOOST_RELEASE_START,
    new Date(new Date(BOOST_RELEASE_START).getTime() + 25 * 60 * 1000),
  );

export const App: FC = () => {
  const launchParams = useLaunchParams();
  // const [location, reactNavigator] = useIntegration(navigator);

  // const tmaNavigator = useMemo(createNavigator, []);
  // const [location, navigator] = useNavigatorIntegration(tmaNavigator);
  // const backButton = useBackButton();

  // useBackButtonIntegration(tmaNavigator, backButton);

  useEffect(() => {
    logger.debug('[Location]:', location.pathname);
  }, [location.pathname]);

  if (!launchParams.initData?.user?.id) {
    return <TechnicalMaintenance />;
  }

  if (
    EnvironmentVariables.MAINTENANCE ||
    !IS_READY_FOR_USER(launchParams.initData.user.id)
  ) {
    return <TechnicalMaintenance />;
  }

  return (
    <AuthenticationHandler
      renderPending={() => {
        return <FullscreenLoader />;
      }}
      renderError={(error) => {
        return <FullscreenError error={error} />;
      }}
    >
      <React.Fragment>
        <BrowserRouter>
          <ToastContainer />
          <ModalRoot>
            <DataFetcher>
              <InitialRedirect>
                <ApplicationRoutes />
              </InitialRedirect>
            </DataFetcher>
          </ModalRoot>
        </BrowserRouter>
      </React.Fragment>
    </AuthenticationHandler>
  );
};
