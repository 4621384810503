import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {rem} from '~/shared/utils/style-helpers';
import {usePrevious} from '@uidotdev/usehooks';
import {useTransition, animated, useSpringRef} from '@react-spring/web';
import {useEffect} from 'react';

import dockLvl1 from './assets/dock-lvl-1.png';
import dockLvl2 from './assets/dock-lvl-2.png';
import dockLvl3 from './assets/dock-lvl-3.png';
import dockLvl4 from './assets/dock-lvl-4.png';

import rocketLvl1 from './assets/rocket-lvl-1.png';
import rocketLvl2 from './assets/rocket-lvl-2.png';
import rocketLvl3 from './assets/rocket-lvl-3.png';
import rocketLvl4 from './assets/rocket-lvl-4.png';

import stakingLvl1 from './assets/staking-lvl-1.png';
import stakingLvl2 from './assets/staking-lvl-2.png';
import stakingLvl3 from './assets/staking-lvl-3.png';
import stakingLvl4 from './assets/staking-lvl-4.png';

import {UpgradeType} from '~/entities/upgrades';
import {hapticFeedback} from '@telegram-apps/sdk';

const assetImg = {
  rocket: [rocketLvl1, rocketLvl2, rocketLvl3, rocketLvl4],
  dock: [dockLvl1, dockLvl2, dockLvl3, dockLvl4],
  staking: [stakingLvl1, stakingLvl2, stakingLvl3, stakingLvl4],
};

interface TransitionTo {
  scale: number;
  transform: string;
  [x: string]: any;
}

const assetAnimation: Record<
  UpgradeType,
  {
    from: TransitionTo;
    enter: TransitionTo;
    leave: TransitionTo;
  }
> = {
  rocket: {
    from: {
      opacity: 0,
      scale: 0.5,
      transform: `translateX(-200%) translateY(200%)`,
    },
    enter: {opacity: 1, scale: 1, transform: `translateX(0%) translateY(0%)`},
    leave: {
      opacity: 0.5,
      scale: 0.7,
      transform: `translateX(200%) translateY(-200%)`,
    },
  },
  dock: {
    from: {
      opacity: 0,
      scale: 0.5,
      transform: `translateX(-200%) translateY(0)`,
    },
    enter: {opacity: 1, scale: 1, transform: `translateX(0%) translateY(0%)`},
    leave: {
      opcaity: 0.5,
      scale: 0.7,
      transform: `translateX(200%) translateY(0)`,
    },
  },
  staking: {
    from: {
      opacity: 0,
      scale: 0.5,
      transform: `translateX(-50%) translateY(200%) rotate(-90deg)`,
    },
    enter: {
      opacity: 1,
      scale: 1,
      transform: `translateX(0%) translateY(0%) rotate(0deg)`,
    },
    leave: {
      opacity: 0.5,
      scale: 2,
      transform: `translateX(30%) translateY(-200%) rotate(45deg)`,
    },
  },
};

export function UpgradeableAsset({
  type,
  lvl,
  ...rest
}: {type: UpgradeType; lvl: number} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();
  const prevLvl = usePrevious(lvl);

  const colors = theme.colors.components.upgrade[type];

  const imgSrc = assetImg[type][(lvl - 1) % assetImg[type].length];

  const transRef = useSpringRef();

  const transitions = useTransition(imgSrc, {
    ref: transRef,
    from: assetAnimation[type].from,
    enter: assetAnimation[type].enter,
    leave: assetAnimation[type].leave,
  });

  useEffect(() => {
    if (prevLvl && prevLvl < lvl) {
      // hapticFeedback.impactOccurred('medium');
    }
  }, [lvl, prevLvl]);

  useEffect(() => {
    transRef.start();
  }, [imgSrc]);

  return (
    <Box
      radius={30}
      backgroundColor={colors.assetBg}
      shadowColor={theme.colors.onSurface}
      borderWidth={3}
      {...rest}
    >
      <div
        css={{
          width: rem(140),
          height: rem(140),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: rem(30),
        }}
      >
        {transitions((style, item) => {
          if (!item) return null;
          return (
            <animated.img
              src={item}
              style={{
                position: 'absolute',
                transformOrigin: 'center',
                ...style,
              }}
              css={{
                maxWidth: '60%',
                maxHeight: '60%',
              }}
            ></animated.img>
          );
        })}
      </div>
    </Box>
  );
}
