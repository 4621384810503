import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';

export type ICreateInvoiceResponse = {
  invoice_link: string;
};

function getInvoiceLink(
  token: IAuthorizationResponse,
  {
    crystalAmount,
  }: {
    crystalAmount: number;
  },
) {
  return apiClient.post<ICreateInvoiceResponse>(
    '/payments/send-payment-request/',
    {
      title: `💎 ${crystalAmount} Crystals`,
      description: 'In-game currency',
      currency: 'XTR',
      provider_token: 'provider_token',
      prices: [
        {
          label: 'Crystals',
          amount: crystalAmount,
        },
      ],
      payload: '{}',
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const PurchaseCrystalsApi = {
  getInvoiceLink,
};
