import React, {ReactText} from 'react';
import {AppTheme, useAppTheme} from '../theme';
import {rem} from '~/shared/utils/style-helpers';
import {css} from '@emotion/react';
import {volumetricBoxShadow} from '../styles';

export type ButtonColor =
  | 'white'
  | 'pink'
  | 'purple'
  | 'orange'
  | 'red'
  | 'blue'
  | 'yellow'
  | 'ghost'
  | 'dark'
  | 'purple-gradient';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: ButtonColor;
  isLoading?: boolean;
  isDisabled?: boolean;
  isFlat?: boolean;
  size?: 'small' | 'medium' | 'large';
  loadingContent?: React.ReactNode;
}

const buttonDisabled = (theme: AppTheme, isDisabled: boolean) => css`
  background-color: #dddce6;
  border-color: #9997a4;
  color: #9997a4;
  --box-shadow-color: #9997a4;
`;

const buttonBase = (theme: AppTheme) => css`
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 9999999px;
  font-size: ${rem(theme.fontSize.s4)};
  letter-spacing: ${rem(0.54)};
  font-weight: 700;
`;

const activeMobile = css`
  transform: translateY(2px);
  box-shadow: 0px 0px 0 0 var(--box-shadow-color);
`;

const activeDesktop = css`
  transform: translateX(2px) translateY(2px);
  box-shadow: 0px 1px 0 0 var(--box-shadow-color);
`;

const idleShadow = css`
  @media (hover: hover) {
    &:hover {
      transform: translateX(-1px) translateY(-2px);
      box-shadow: 3px 5px 0px 0px var(--box-shadow-color);
    }

    &:active {
      ${activeDesktop}
    }
  }

  @media (hover: none) {
    &:hover {
      transform: translateX(-1px) translateY(-2px);
      box-shadow: 3px 5px 0px 0px var(--box-shadow-color);
    }
    &:active {
      ${activeMobile}
    }
  }
`;

const loadingShadow = css`
  @media (hover: hover) {
    ${activeDesktop}
  }
  @media (hover: none) {
    ${activeMobile}
  }
`;

export const buttonHoverBehavior = (
  theme: AppTheme,
  {
    shadowColor,
    isLoading,
    isDisabled,
  }: {
    shadowColor: string;
    isLoading: boolean;
    isDisabled: boolean;
  },
) => {
  return css`
    transition: all 0.2s ease;
    --box-shadow-color: ${shadowColor};
    ${volumetricBoxShadow(theme)};
    ${isLoading || isDisabled ? loadingShadow : idleShadow}
    ${isDisabled ? buttonDisabled(theme, isDisabled) : ''}
  `;
};

const SMALL_BUTTON = {
  pressed: css`
    transform: translateX(1px) translateY(1px);
    box-shadow: 0px 0px 0 0 var(--box-shadow-color);
  `,
  raised: css`
    transform: translateX(-1px) translateY(-1px);
    box-shadow: 2px 2px 0px 0px var(--box-shadow-color);
  `,
  idle: css`
    box-shadow: 1px 1px 0 0 var(--box-shadow-color);
  `,
};

const smallButtonHoverBehaviour = (
  theme: AppTheme,
  {
    shadowColor,
    isLoading,
    isDisabled,
  }: {
    shadowColor: string;
    isLoading: boolean;
    isDisabled: boolean;
  },
) => {
  const isInteractive = !isLoading && !isDisabled;

  return css`
    --box-shadow-color: ${shadowColor};

    ${isInteractive &&
    css`
      ${SMALL_BUTTON.idle}
      @media (hover: hover) {
        &:hover {
          ${SMALL_BUTTON.raised}
        }

        &:active {
          ${SMALL_BUTTON.pressed}
        }
      }

      @media (hover: none) {
        &:active {
          ${SMALL_BUTTON.pressed}
        }
      }
    `}
    ${isLoading ? SMALL_BUTTON.pressed : ''}
    ${isDisabled ? buttonDisabled(theme, isDisabled) : ''}
  `;
};

const BUTTON_GRADIENT = (theme: AppTheme) => ({
  surface: 'linear-gradient(88deg, #E446BA 0%, #7C32FF 100%)',
  onSurface: theme.colors.onSurfaceAlternative,
  border: theme.colors.onSurface,
});

const GHOST_COLOR_SET = (theme: AppTheme) => ({
  surface: 'transparent',
  onSurface: theme.colors.onSurfaceSecondary,
  border: theme.colors.onSurfaceSecondary,
});

const DARK_COLOR_SET = (theme: AppTheme) => ({
  surface: theme.colors.shades.dark.surface,
  onSurface: theme.colors.shades.dark.onSurface,
  border: theme.colors.shades.dark.onSurface,
});

export interface IIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
  loadingContent?: React.ReactNode;
}

export function IconButton({
  children,
  backgroundColor,
  loadingContent = children,
  isLoading = false,
  isDisabled = false,
  isSmall,
  ...rest
}: IIconButtonProps) {
  const theme = useAppTheme();
  const hoverBehaviour = isSmall
    ? smallButtonHoverBehaviour
    : buttonHoverBehavior;

  const size = isSmall ? 28 : 50;

  return (
    <button
      disabled={isDisabled}
      css={css`
        ${buttonBase(theme)}

        background: ${backgroundColor};
        width: ${rem(size)};
        height: ${rem(size)};

        ${hoverBehaviour(theme, {
          shadowColor: theme.colors.onSurface,
          isLoading,
          isDisabled,
        })}
      `}
      {...rest}
    >
      {isLoading ? loadingContent : children}
    </button>
  );
}

function useButtonColorSet(color: ButtonColor) {
  const theme = useAppTheme();

  const isGhost = color === 'ghost';
  const isDark = color === 'dark';

  const colorSet = isGhost
    ? GHOST_COLOR_SET(theme)
    : isDark
    ? DARK_COLOR_SET(theme)
    : color === 'purple-gradient'
    ? BUTTON_GRADIENT(theme)
    : theme.colors.accents[color];

  return {isGhost, isDark, colorSet};
}

export function Button({
  children,
  color,
  isLoading = false,
  isDisabled = false,
  isFlat = false,
  loadingContent = children,
  onClick,
  size = 'medium',
  ...rest
}: IButtonProps) {
  const theme = useAppTheme();

  const {colorSet, isGhost, isDark} = useButtonColorSet(color);

  const sizing = {
    medium: {
      padding: `${rem(10)} ${rem(40)}`,
      height: rem(53),
      fontSize: rem(theme.fontSize.s4),
    },
    small: {
      padding: `${rem(10)} ${rem(20)}`,
      height: rem(34),
      fontSize: rem(theme.fontSize.s6),
    },
    large: {
      padding: `${rem(10)} ${rem(60)}`,
      height: rem(73),
      fontSize: rem(theme.fontSize.s3),
    },
  };
  return (
    <button
      disabled={isDisabled}
      css={css`
        ${buttonBase(theme)}
        background: ${isDisabled ? '#C2C0DC' : colorSet.surface};
        color: ${isDisabled ? '#7E7E7E' : colorSet.onSurface};

        font-size: ${sizing[size].fontSize};
        height: ${sizing[size].height};
        padding: ${sizing[size].padding};
        border-style: solid;
        border-width: ${isGhost || isDark || isFlat ? 0 : 2}px;
        border-color: ${colorSet.border};
        ${!isFlat &&
        buttonHoverBehavior(theme, {
          shadowColor: isGhost || isDark ? 'transparent' : colorSet.border,
          isLoading,
          isDisabled,
        })}
      `}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        onClick?.(e);
      }}
      {...rest}
    >
      {isLoading ? loadingContent : children}
    </button>
  );
}

export function UpgradeButton({
  children,
  color,
  isLoading = false,
  isDisabled = false,
  loadingContent = children,
  onClick,
  ...rest
}: IButtonProps) {
  const theme = useAppTheme();

  const {colorSet, isGhost} = useButtonColorSet(color);

  return (
    <button
      disabled={isDisabled}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        onClick?.(e);
      }}
      css={css`
        ${buttonBase(theme)}
        ${smallButtonHoverBehaviour(theme, {
          isLoading,
          shadowColor: colorSet.onSurface,
          isDisabled,
        })}

        border: 1px solid ${colorSet.onSurface};
        background: ${colorSet.surface};
        color: ${colorSet.onSurface};
        padding: ${rem(10)} ${rem(13)};
        font-size: ${rem(theme.fontSize.s6)};
      `}
      {...rest}
    >
      {isLoading ? loadingContent : children}
    </button>
  );
}

export function MicroButton({
  children,
  color,
  isLoading = false,
  isDisabled = false,
  onClick,
  loadingContent = children,
  ...rest
}: IButtonProps) {
  const theme = useAppTheme();

  const {colorSet, isGhost} = useButtonColorSet(color);

  return (
    <button
      css={css`
        ${buttonBase(theme)}
        ${smallButtonHoverBehaviour(theme, {
          shadowColor: colorSet.onSurface,
          isLoading,
          isDisabled,
        })}
        border: 1px solid ${colorSet.onSurface};
        color: ${colorSet.onSurface};
        background: ${colorSet.surface};
        padding: ${rem(7)} ${rem(12)};
        height: ${rem(32)};
        font-size: ${rem(theme.fontSize.s6)};
        font-weight: 500;
      `}
      disabled={isDisabled}
      onClick={(e) => {
        if (isDisabled || isLoading) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        onClick?.(e);
      }}
      {...rest}
    >
      {isLoading ? loadingContent : children}
    </button>
  );
}

export function TextButton({
  children,
  color,
  isLoading = false,
  isDisabled = false,
  onClick,
  loadingContent = children,
  ...rest
}: IButtonProps) {
  const theme = useAppTheme();

  const {colorSet, isGhost} = useButtonColorSet(color);

  return (
    <button
      css={css`
        ${buttonBase(theme)}
        color: ${colorSet.surface};
        padding: ${rem(7)} ${rem(12)};
        height: ${rem(32)};
        font-size: ${rem(theme.fontSize.s6)};
        font-weight: 600;
      `}
      disabled={isDisabled}
      onClick={(e) => {
        if (isDisabled || isLoading) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        onClick?.(e);
      }}
      {...rest}
    >
      {isLoading ? loadingContent : children}
    </button>
  );
}

export function ButtonLike({
  children,
  color,
  orientation,
  ...rest
}: {
  color: string;
  orientation: 'left' | 'right';
} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <div
      css={css`
        ${buttonBase(theme)}
        fontSize: ${rem(theme.fontSize.s6)};
        background: ${color};
        color: ${theme.colors.accents.pink.onSurface};
        border: 2px solid ${theme.colors.accents.pink.onSurface};
        ${volumetricBoxShadow(theme, {
          orientation,
          shadowColor: theme.colors.accents.pink.onSurface,
        })}
      `}
      {...rest}
    >
      {children}
    </div>
  );
}
