import {hapticFeedback} from '@telegram-apps/sdk-react';
import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useAppTheme} from '~/components/kit';
import {Button} from '~/components/kit/button/button';
import {useDockModel} from '~/entities/dock';
import {useUserQuery} from '~/entities/user';
import {useDockProgress} from '~/features/idle-mining';
import {useClaimMoons} from '~/features/idle-mining/model';
import {DockProgressBar} from '~/features/idle-mining/ui/dock-progress-bar';
import {opacity} from '~/shared/utils/colors';
import {formatRemainingSeconds} from '~/shared/utils/format/format-time';
import {rem} from '~/shared/utils/style-helpers';

interface IDockProgressProps extends React.HTMLProps<HTMLDivElement> {}

function WaitTime({timeLeft, i18nKey}: {timeLeft: number; i18nKey: string}) {
  const {t} = useTranslation();
  const theme = useAppTheme();

  return (
    <div
      css={{
        color: theme.colors.onSurfaceSecondary,
        fontWeight: 500,
      }}
    >
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={{
          bold: (
            <span css={{fontWeight: 800, color: theme.colors.onSurface}}></span>
          ),
        }}
        values={{
          time: formatRemainingSeconds(Math.floor(timeLeft / 1000)),
        }}
      ></Trans>
    </div>
  );
}

function ClaimButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const [isButtonLoading, setIsLoading] = React.useState(false);

  const dockModel = useDockModel();
  const claim = useClaimMoons();
  const user = useUserQuery();

  const {t} = useTranslation();

  const isLoading =
    claim.isPending || dockModel.dockQuery.isLoading || user.isLoading;

  return (
    <Button
      isDisabled={isButtonLoading}
      isLoading={isButtonLoading}
      onClick={async () => {
        setIsLoading(true);
        try {
          await claim.mutateAsync();
          await dockModel.dockQuery.refetch();
          await user.refetch();
        } finally {
          setIsLoading(false);
        }
      }}
      {...props}
      color="pink"
    >
      {claim.isPending ? 'Claiming' : t('mining.buttonClaimToStorage')}
    </Button>
  );
}

export function DockProgressAndButton() {
  const dockModel = useDockModel();
  const {t} = useTranslation();
  const theme = useAppTheme();

  const {timeLeft, canClaim, isFull, percentageProgress, timeUntilFull} =
    useDockProgress({
      idleMining: {
        min_claim_time: dockModel.dock.min_claim_time,
        last_claimed_time: dockModel.dock.last_claimed_time,
        combined_lunar_loot_speed: dockModel.dock.combined_lunar_loot_speed,
        dock_size: dockModel.dock.dock_size,
      },
    });

  useEffect(() => {
    if (isFull) {
      // hapticFeedback.impactOccurred('heavy');
    }
  }, [isFull]);

  useEffect(() => {
    if (canClaim) {
      // hapticFeedback.impactOccurred('light');
    }
  }, [canClaim]);

  return (
    <div
      css={{
        textAlign: 'center',
      }}
    >
      {isFull ? (
        <div
          css={{
            color: theme.colors.onSurfaceCritical,
            fontWeight: 500,
            fontSize: theme.fontSize.s4,
          }}
        >
          {t('mining.dockFull')}
        </div>
      ) : canClaim ? (
        <WaitTime timeLeft={timeUntilFull} i18nKey="mining.dockFullIn" />
      ) : (
        <WaitTime timeLeft={timeLeft} i18nKey="mining.claimWait" />
      )}
      <div
        css={{
          marginBottom: rem(14),
          marginTop: rem(14),
        }}
      >
        {canClaim ? (
          <ClaimButton
            css={{
              width: '100%',
            }}
          />
        ) : (
          <DockProgressBar percentage={percentageProgress * 100} />
        )}
      </div>
      <div
        css={{
          color: opacity(theme.colors.onSurfaceSecondary, 0.7),
          fontWeight: 500,
          fontSize: theme.fontSize.s7,
        }}
      >
        {t('mining.claimHint')}
      </div>
    </div>
  );
}
